import React, {
  useState,
  useCallback,
  Fragment,
  useEffect,
  lazy,
  Suspense,
} from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  ToggleButtonGroup,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DataServices from "../../Services/DataServices";
import CardHeader from "@mui/material/CardHeader";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuList from "../../Constants/list";
import Loading from "../../Controller/LoadingController/Loading";
import { colors } from "@mui/material";
import Box from "@mui/material";

import CardCustomHeader from "../../Controller/HeaderController/CardCustomHeader";
import Header from "../../Controller/HeaderController/Header";
import SeverityBasedView from "./SeverityBasedView";
import { StreetDataTable } from "./StreetDatatable";
import { LapsesChart } from "./LapsesChart";
import { AssetsChart } from "./AssertsChart";
import { RoutesChart } from "./RoutesChart";
import ToggleButton from "@mui/material/ToggleButton";
import moment from "moment/moment";

//import ComponentOne from "./ComponentOne"

// const CardCustomHeader = React.lazy(() => import('../../Controller/HeaderController/CardCustomHeader'));
// const Header = React.lazy(() => import('../../Controller/HeaderController/Header'));
// const SeverityBasedView = React.lazy(() => import('./SeverityBasedView'));
// const StreetDataTable = React.lazy(() => import('./StreetDatatable'));
// const LapsesChart = React.lazy(() => import('./LapsesChart'));
// const AssetsChart = React.lazy(() => import('./AssertsChart'));
// const RoutesChart = React.lazy(() => import('./RoutesChart'));
const ComponentOne = React.lazy(() => import("./ComponentOne"));

const OneMapComponent = React.lazy(() => import("./MapView/OneMap"));

const cardContentStyle = {
  paddingTop: `2px`,
  paddingBottom: 5,
  paddingLeft: `2px`,
  paddingRight: `2px`,
};

function HomeComponent() {
  const theme = useTheme();
  const [severityPercent, setSeverityPercent] = React.useState({});
  const [sectorChart, setSectorChart] = React.useState({});
  const [criticalCount, setCriticalCount] = React.useState(0);
  const [sensorDevices, setSensorDevices] = React.useState([]);
  const [sensorCriticalCount, setSensorCriticalCount] = React.useState(0);
  const [selectODC, setSelectODC] = useState("odcai_track2");
  const timeRanges = [
    { label: "9PM-4AM", start: "21", end: "4" },
    { label: "4AM-11AM", start: "4", end: "11" },
    { label: "11AM-4PM", start: "11", end: "4" },
    { label: "4PM-9PM", start: "4", end: "21" },
  ];

  const timeVariable = parseFloat(
    `${moment().format("H")}.${moment().format("mm")}`
  );

  const getTimeRange = () => {
    if (timeVariable == 21 || timeVariable > 21 || timeVariable < 4.1) {
      return "1";
    } else if (timeVariable == 4 || timeVariable < 11) {
      return "2";
    } else if (timeVariable == 11 || timeVariable < 16) {
      return "3";
    } else {
      return "4";
    }
  };
  const [selectedTime, setselectedTime] = useState(getTimeRange());
  const [connectWebsocket, setConnectWebsocket] = useState(true);

  const handleChange = (event, newAlignment) => {
    setselectedTime(newAlignment);
    handleWebSocketChange(newAlignment);
  };

  const handleWebSocketChange = (newTimeRange) => {
    console.log('newTimeRange', newTimeRange);
    console.log('getTimeRange()', getTimeRange());
    if (newTimeRange !== getTimeRange()) {
      setConnectWebsocket(false);
      console.log('Disconnecting WebSocket');
    } else {
      setConnectWebsocket(true);
      console.log('Connecting WebSocket');
    }
  };

  const control = {
    value: selectedTime,
    onChange: handleChange,
    exclusive: true,
  };

  const gridStyleForCard = {
    backgroundColor: "#4B5563",
    textAlign: `left`,
    color: theme.palette.warning.main,
    fontSize: `7px`,
    paddingLeft: `5px`,
  };

  const handleODCChange = (event) => {
    setSelectODC(event.target.value);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    if (sensorDevices != null && sensorDevices.length > 0) {
      let sData = sensorDevices.filter((sc) => sc.level > 80);
      setSensorCriticalCount(sData.length);
    }
  }, [sensorDevices]);

  const setData = (data) => {
    setSeverityPercent({
      critical: data.criticalPercentage,
      moderate: data.moderatePercentage,
      clean: data.cleanPercentage,
    });
    setCriticalCount(data.criticalCount);
    setSectorChart(data.severityChart);
  };

  const retrieveData = async () => {
    await DataServices.getSectorSeverity()
      .then((response) => {
        // console.log('response from getSectorSeverity: '+JSON.stringify(response.data.data));
        setData(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Grid container spacing={0} style={{ marginTop: `-20px` }}>
      <Grid item xs={12} md={12} sm={12}>
        <Header title={`On Demand Cleaning Optimization`} />
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <SeverityBasedView />
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={9.5} sm={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} sm={12}>
                <Suspense
                  fallback={
                    <div>
                      <Loading loading={true} type={`dots`} backDrop={false} />
                    </div>
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    style={{
                      position: "absolute",
                      zIndex: 9999,
                      marginTop: "1%",
                      marginLeft: "1%",
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        display: "flex",
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        flexWrap: "wrap",
                      }}
                    >
                      <ToggleButtonGroup
                        size="small"
                        {...control}
                        aria-label="Small sizes"
                      >
                        <ToggleButton value="1" key="1">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              fontSize: 10,
                            }}
                          >
                            9 PM-4 AM
                          </Typography>
                        </ToggleButton>
                        <ToggleButton value="2" key="2">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              fontSize: 10,
                            }}
                          >
                            4 AM-11 AM
                          </Typography>
                        </ToggleButton>
                        <ToggleButton value="3" key="3">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              fontSize: 10,
                            }}
                          >
                            11 AM-4 PM
                          </Typography>
                        </ToggleButton>
                        <ToggleButton value="4" key="4">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              fontSize: 10,
                            }}
                          >
                            4 PM-9 PM
                          </Typography>
                        </ToggleButton>
                        <ToggleButton value="5" key="5">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              fontSize: 10,
                            }}
                          >
                            24 Hours
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Paper>
                  </Grid>
                  <OneMapComponent
                    odcValue={selectODC}
                    count={(e) => setSensorDevices(e)}
                    selectedtime={selectedTime}
                    connectWebsocket={connectWebsocket}
                  />
                </Suspense>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Card>
                  <RoutesChart />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2.5} sm={2}>
            <Grid container spacing={0.5}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  sx={{ m: 1 }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={selectODC}
                  label="Select the vehicle"
                  onChange={handleODCChange}
                  size="small"
                  select
                  SelectProps={{ IconComponent: () => null }}
                  fullWidth
                >
                  {MenuList.length > 0 &&
                    MenuList.map((x, i) => {
                      return (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Card>
                  <CardContent style={cardContentStyle}>
                    {/* <CardCustomHeader title={`STREETS`} varient={'body2'} alignment={`center`}/>      */}
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={gridStyleForCard}
                      >
                        <p>STREETS</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <StreetDataTable
                          severityPercent={severityPercent}
                          sectorChart={sectorChart}
                          criticalCount={criticalCount}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Card>
                  <CardContent style={cardContentStyle}>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={gridStyleForCard}
                      >
                        <p>
                          {selectODC == "dicSensor"
                            ? "DIC SENSOR FEEDBACK"
                            : selectODC == "smartCombi"
                            ? "SMART COMBI ARM FEEDBACK"
                            : "ON-DEMAND CLEANING FEEDBACK"}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Card
                          style={{
                            backgroundColor: theme.palette.info.main,
                            margin: 4,
                          }}
                        >
                          <Typography
                            variant="h4"
                            style={{ padding: "10px", textAlign: `center` }}
                          >
                            {selectODC == "dicSensor"
                              ? `${sensorCriticalCount}`
                              : selectODC == "smartCombi"
                              ? `${"300/1000"}`
                              : `${criticalCount}`}
                          </Typography>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Card>
                  <CardContent style={cardContentStyle}>
                    {/*  <CardCustomHeader title={`ASSETS`} varient={'body2'} alignment={`center`}/>  */}
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={gridStyleForCard}
                      >
                        <p>ASSETS</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <AssetsChart />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Card>
                  <CardContent style={cardContentStyle}>
                    {/* <CardCustomHeader title={`LAPSES`} varient={'body2'} alignment={`center`}/> */}
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={gridStyleForCard}
                      >
                        <p>LAPSES</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <LapsesChart />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomeComponent;

/*
<Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={3}>
                        <Card>
                        
                            <CardContent>  
                                <CardCustomHeader title={`ASSETS`} />                         
                                <AssetsChart />
                            </CardContent>
                        </Card>                        
                    </Grid>
                    <Grid item xs={12} md={3} sm={3}>
                        <Card>
                            <CardCustomHeader title={`LAPSES`} />     
                            <CardContent>  
                                
                                <LapsesChart />
                            </CardContent>
                        </Card>  
                        
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                        <Card>
                        <CardCustomHeader title={`ROUTES`} />
                            <CardContent>  
                                
                            <RoutesChart />
                            </CardContent>
                        </Card>  
                    </Grid>
                </Grid>
            </Grid> 
*/
