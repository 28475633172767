import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colorConfigs from '../configs/colorConfigs';
import { Card, CardContent } from '@mui/material';
import Logo from "../Assets/ctm-01.png"
import Copyright from './Copyright';
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/auth"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ToastController from '../Controller/ToastController/ToastController';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(8).max(32).required(),
});


export default function SignIn() {
  const { register, handleSubmit, formState: { errors } } = useForm({resolver: yupResolver(validationSchema),  mode: 'onChange'});
  const navigate = useNavigate();
  const [successBox, setSuccessBox]  = React.useState(false);
  const [toastMsg, setToastMsg]= React.useState({
    msg: ``,
    status: ``
  });

  const handleLogin = (data) => {
    //console.log("handleLogin", data)
    AuthService.login(data).then(resp => {
        navigate("/dashboard/live-overview");
    }).catch(err => {
      console.log(JSON.stringify(err));
      setSuccessBox(true)
      setToastMsg((prevState) => ({
        ...prevState,
        msg:  `Failed to login, Authentication failed.`,
        status: 'error'
     }));
      //setAlert({show: true, text: "Username or password is invalid",type: "error",title:"Failure"});
    })
  }
const handleError = (errors) => {
  console.log("error", errors, register)
  let e = errors && errors.email && errors.email.message;
  let p =  errors && errors.password && errors.password.message;
  let x = e ? e : p ? p : "Failed to login, Authentication failed."

  setSuccessBox(true)
  setToastMsg((prevState) => ({
    ...prevState,
    msg:  x,
    status: 'error'
  }));
};

const onClose =(e)=>{
  setSuccessBox(false)
}


  return (
    //<div >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{backgroundColor: colorConfigs.mainBg, height: `100vh`}}
        >
            <Card sx={{ maxWidth: 500 }}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={Logo} width={60}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography component="h1" variant="body2" style={{textAlign: `center`}}>
                      Please sign in to your account below.
                    </Typography>
                </Grid>
                <form onSubmit={handleSubmit(handleLogin, handleError)}>
                  <Grid container spacing={2}  style={{padding: `10px`}}> 
                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="email"
                              label="Email"
                              fullWidth
                              {...register('email')}
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              type='password'
                              name="password"
                               id="examplePassword"
                              label="Password"
                              {...register('password')}
                              fullWidth
                          />
                      </Grid>     

                        <Grid item xs={12} md={12} sm={12}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained">
                            Sign In
                          </Button>
                      </Grid>                  
                  </Grid>                    
                </form>
              
                <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center" style={{padding: `10px`}}>
                  <Grid item xs={4} sm={6} md={6}>
                    <Link onClick={()=>  navigate("/recoverpassword")} variant="body2">
                      Recover Password
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6} >
                    <Link onClick={()=>  navigate("/signup")} variant="body2" style={{float: 'right'}}>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Copyright />
                </Grid>
              </CardContent>
            </Card>
            {successBox &&(
            <ToastController 
            isOpen={successBox}
            onClose={onClose}
            message={toastMsg.msg}
            status={toastMsg.status}/>
          )}
        </Grid>
    //</div>
  );
}