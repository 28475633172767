import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import colorConfigs from "../configs/colorConfigs";
import { Card, CardContent } from "@mui/material";
import Logo from "../Assets/ctm-01.png";
import Copyright from "./Copyright";
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ToastController from "../Controller/ToastController/ToastController";
import { alignProperty } from "@mui/material/styles/cssUtils";

const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
});

const resetPasswordValidationSchema = Yup.object().shape({
  verificationCode: Yup.string().required("Verification code is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  repeatPassword: Yup.string()
    .required("Repeat password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default function ForgotPassword() {
  const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] =
    React.useState(false);
  const validationSchema = isForgotPasswordSuccess
    ? resetPasswordValidationSchema
    : forgotPasswordValidationSchema;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const [successBox, setSuccessBox] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState({
    msg: ``,
    status: ``,
  });

  const handleForgotPassword = (data) => {
    AuthService.forgotPassword(data)
      .then((resp) => {
        //console.log(JSON.stringify(resp));
        //navigate("/signin");
        setIsForgotPasswordSuccess(true);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        setSuccessBox(true);
        setToastMsg((prevState) => ({
          ...prevState,
          msg: `Username is invalid`,
          status: "error",
        }));
      });
  };

  const handleResetPassword = (data) => {
    console.log("handleResetPassword", data);
    AuthService.forgotPasswordSubmit(data)
      .then((resp) => {
        //console.log(JSON.stringify(resp));
        navigate("/signin");
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        setSuccessBox(true);
        setToastMsg((prevState) => ({
          ...prevState,
          msg: `Error while resetting password`,
          status: "error",
        }));
      });
  };

  const handleError = (errors) => {
    console.log(errors);
    let e = errors && errors.email && errors.email.message;
    let p = errors && errors.password && errors.password.message;
    let x = e ? e : p ? p : "Username is invalid";

    setSuccessBox(true);
    setToastMsg((prevState) => ({
      ...prevState,
      msg: x,
      status: "error",
    }));
  };

  const onClose = (e) => {
    setSuccessBox(false);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: colorConfigs.mainBg, height: `100vh` }}
    >
      <Card sx={{ maxWidth: 500, minWidth: 400 }}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={Logo} width={60} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              component="h1"
              variant="body2"
              style={{ textAlign: `center` }}
            >
              Forgot your Password?
            </Typography>
          </Grid>
          {!isForgotPasswordSuccess && (
            <form onSubmit={handleSubmit(handleForgotPassword, handleError)}>
              <Grid container spacing={2} style={{ padding: `10px` }}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    {...register("email")}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <Button type="submit" variant="contained">
                  Send Mail
                </Button>
              </Grid>
            </form>
          )}

          {isForgotPasswordSuccess && (
            <form onSubmit={handleSubmit(handleResetPassword, handleError)}>
              <Grid container spacing={2} style={{ padding: `10px` }}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    type="password"
                    name="verificationCode"
                    id="outlined-required"
                    label="Verification Code"
                    {...register("verificationCode")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    type="password"
                    name="password"
                    id="outlined-required"
                    label="Password"
                    {...register("password")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    type="password"
                    name="repeatPassword"
                    id="outlined-required"
                    label="Repeat Password"
                    {...register("repeatPassword")}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <Button type="submit" variant="contained">
                  Reset Password
                </Button>
              </Grid>
            </form>
          )}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: `10px` }}
            spacing={2}
          >
            <Grid item xs={4} sm={3} md={3}>
              <Link onClick={() => navigate("/signin")} variant="body2">
                {"Sign In"}
              </Link>
            </Grid>
            <Grid item xs={8} sm={9} md={9}>
              <Link
                onClick={() => navigate("/signup")}
                variant="body2"
                style={{ float: "right" }}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Copyright />
          </Grid>
        </CardContent>
      </Card>
      {successBox && (
        <ToastController
          isOpen={successBox}
          onClose={onClose}
          message={toastMsg.msg}
          status={toastMsg.status}
        />
      )}
    </Grid>
  );
}
