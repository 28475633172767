import * as React from 'react'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ComponentOne from '../Components/Maps/ComponentOne';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import StreetviewIcon from '@mui/icons-material/Streetview';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import StreamIcon from '@mui/icons-material/Stream';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import SensorsIcon from '@mui/icons-material/Sensors';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import StreetsLayout from './StreetsLayout';
import TrendLayout from './TrendLayout';
import DashboardLayout from './DashboardLayout';
import DicComponent from '../Components/Dic';
import BinComponent from '../Components/Bin';
import ReportLayout from './ReportLayout';
import EvStationIcon from '@mui/icons-material/EvStation';
import ScheduleIcon from '@mui/icons-material/Schedule';
const HomeComponent = React.lazy(() => import('../Components/Maps/index'))
const AddUser = React.lazy(() => import('../Components/User/AddUser'))
const ModifyStreets = React.lazy(() => import('../Components/Streets/ModifyStreets'))
const AddStreets = React.lazy(() => import('../Components/Streets/AddStreets'))
const LiveReport = React.lazy(()=>import('../Components/Reports/LiveReports'))
const TrendAnalysisOverview = React.lazy(()=>import('../Components/Trend/TrendAnalysisOverview/index'))
const TrendAnalysis = React.lazy(()=>import('../Components/Trend/TrendAnalysis/index'))
const Areas = React.lazy(()=>import('../Components/PedestrainAreas/Areas'))
const RfIdReport = React.lazy(()=>import('../Components/RfIdLogs/index'))
const CleaningFrequencyReport = React.lazy(()=>import('../Components/Reports/CleaningFrequency.js'))

// import HomeComponent from '../Components/Maps/index';
// import AddUser from '../Components/User/AddUser'
// import ModifyStreets from '../Components/Streets/ModifyStreets';
// import AddStreets from '../Components/Streets/AddStreets';
// import LiveReport from '../Components/Reports/LiveReports';


const appRoutes = [
    {
        index: true,
        element: <HomeComponent />,
        state: "Home"
    },

    {
      path: "/dashboard",
      state: "Dashboard",
      element: <DashboardLayout />,
      sidebarProps: {
        displayText: "Dashboard",
        icon: <HomeIcon />
      },
      child: [
        {
          path: "/dashboard/live-overview",
          element: <HomeComponent />,
          state: "Home",
          sidebarProps: {
            displayText: "Live Overview",
            icon: <StreamIcon />
          }
        }, 
        
        {
          path: "/dashboard/dic-sensor",
          element: <DicComponent />,
          state: "Dic",
          sidebarProps: {
            displayText: "Dic Sensor",
            icon: <SensorsIcon />
          }
        }, 

        {
          path: "/dashboard/bin",
          element: <BinComponent />,
          state: "Litter Bins",
          sidebarProps: {
            displayText: "Litter Bins",
            icon: <DeleteSweepIcon />
          }
        }, 
      ]
    },
    

    {
      path: "/reports",
      element: <ReportLayout />,
      state: "Report",
      sidebarProps: {
        displayText: "Reports",
        icon: <SummarizeIcon />
      },
      child: [
        {
          path: "/reports/livereports",
          element: <LiveReport />,
          state: "LiveReport",
          sidebarProps: {
            displayText: "Live Report",
            icon: <LegendToggleIcon />
          }
        }, 
        {
          path: "/reports/rfidreports",
          element: <RfIdReport />,
          state: "RfidLogs",
          sidebarProps: {
            displayText: "Bin History",
            icon: <WaterfallChartIcon />
          }
        }, 
        {
          path: "/reports/cleaningFrequencyReports",
          element: <CleaningFrequencyReport />,
          state: "CleaningFrequencyReports",
          sidebarProps: {
            displayText: "Cleaning Frequency",
            icon: <ScheduleIcon />
          }
        },
      ]
    }, 
  
    {
      path: "/trend",
      element: <TrendLayout />,
      state: "trend",
      sidebarProps: {
        displayText: "Trend",
        icon: <AssessmentIcon />
      },
      child: [
        {
          path: "/trend/analysis",
          element: <TrendAnalysis />,
          state: "TrendAnalysis",
          sidebarProps: {
            displayText: "Trend Analysis",
            icon: <TrendingUpIcon />
          }
        }, 
        
        {
          path: "/trend/overview",
          element: <TrendAnalysisOverview />,
          state: "TrendAnalysisOverview",
          sidebarProps: {
            displayText: "Trend Analysis Overview",
            icon: <BarChartIcon />
          }
        }, 
      ]
    },

    {
      path: "/adduser",
      element: <AddUser />,
      state: "AddUser",
      sidebarProps: {
        displayText: "Add User",
        icon: <PersonAddIcon />
      }
    }, 

    {
      path: "/streets",
      element: <StreetsLayout />,
      state: "street",
      sidebarProps: {
        displayText: "Streets",
        icon: <StreetviewIcon />
      },
      child: [
        {
          path: "/streets/addstreets",
          element: <AddStreets />,
          state: "street.addstreets",
          sidebarProps: {
            displayText: "Add Streets",
            icon: <AddRoadIcon />
          }
        },
        {
          path: "/streets/modifystreets",
          element: <ModifyStreets />,
          state: "street.modifystreets",
          sidebarProps: {
            displayText: "Streets",
            icon: <EditRoadIcon />
          },
        }
      ]
    },
 
    {
      path: "/areas",
      element: <Areas />,
      state: "Area",
      sidebarProps: {
        displayText: "Area",
        icon: <SummarizeIcon />
      }
    }, 



 /*  {
     path: "/streets",
    element: <ModifyStreets />,
    //state: "Streets",
    sidebarProps: {
      displayText: "Streets",
      icon: <EditRoadIcon />
    },
    child: [
      {
        path: "/modifystreets",
        element: <ModifyStreets />,
        state: "ModifyStreets",
        sidebarProps: {
          displayText: "Modify Streets",
          icon: <EditRoadIcon />
        }
      }, 
      {
        path: "/addstreets",
        element: <AddStreets />,
        state: "AddStreets",
        sidebarProps: {
          displayText: "Add Streets",
          icon: <AddRoadIcon />
        }
      }, 
    ]
},*/
];

export default appRoutes;