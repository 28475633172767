import { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";

Auth.configure(awsconfig);

const login = (data) => {
  return Auth.signIn({
    username: data.email,
    password: data.password,
  }).then((response) => {
    if (response !== null) {
      localStorage.clear();
      localStorage.setItem("user", JSON.stringify(response));
    }
    return response;
  });
};

const isAuthenticated = () => {
  const authenticated = true; //TOBE CHANGED
  return Auth.currentAuthenticatedUser()
    .then((resp) => {
      authenticated = true;
      return authenticated;
    })
    .catch((err) => {
      console.log("[Error]Current Authenticated User: " + JSON.stringify(err));
      return authenticated;
    });
};

const register = (data) => {
  return Auth.signUp({
    username: data.email,
    password: data.password,
    attributes: {
      email: data.email,
      name: data.name,
    },
  });
};

const forgotPassword = (data) => {
  return Auth.forgotPassword(data.email);
};

const forgotPasswordSubmit = (data) => {
  console.log("dataa", data.email, data.verificationCode, data.password);
  return Auth.forgotPasswordSubmit(
    data.email,
    data.verificationCode,
    data.password
  );
};

const logout = () => {
  localStorage.removeItem("user");
  Auth.currentUserInfo().then((user) => {
    //user.signOut();
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export default {
  login,
  register,
  forgotPassword,
  logout,
  getCurrentUser,
  isAuthenticated,
  forgotPasswordSubmit,
};
