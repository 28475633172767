import Typography from '@mui/material/Typography';
import moment from 'moment';


export default function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © CTM '}
      {moment().year()}
    </Typography>
    );
  }